.EngagementCard{
  background: var(--green-l2);
  @include flex(center);
  flex-direction: column;
  padding: 3em;
  height: 100%;

  > *{
    text-align: center;
  }

  .head{
    position: relative;

    @include responsive($tabletSmall, max){
      margin-bottom: 3em;
    }

    @include responsive($tabletSmall){
      margin-bottom: get-vw(70);
    }

    .visual__container{
      @include imgRatio(200, 200);
      border-radius: 50%;
      width: get-vw(200);
      background: var(--black);

      @include responsive($tabletSmall, max){
        min-width: 200px;
      }
    }

    span{
      font: 500 120px var(--main-font);
      color: var(--green-l1);
      position: absolute;
      bottom: 0; left: 0;
      transform: translate(-50%, 0);
      line-height: 0.7;

      @include responsive($tabletSmall){
        @include font-vw(120);
      }

      @include responsive($deskXL){
        font-size: 120px;
      }
    }
  }

  // Title
  > span{
    font: 500 32px var(--title-font);
    color: var(--black);

    @include responsive($tabletSmall){
      @include font-vw(32);
    }
    
    @include responsive($deskXL){
      font-size: 32px;
    }
  }

  > .wswyg--content{
    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(24);
    }
  }
}