:root {
  // Colors
  --black-d1: #2D2D2B;
  --black: #383837;
  --black-l2: #414140;
  --white: #FFFFFF;
  --grey-d1: #DEDEDE;
  --grey: #EEEEEE;
  --text: #76746A;
  --blue-d1: #263F4E;
  --blue: #38738B;
  --blue-l1: #95ACBA;
  --green-d1: #5E7F65;
  --green: #66B873;
  --green-l1: #9BA39F;
  --green-l2: #C6CECA;
  --base: #1a1a1a;
  --links-color: var(--base);
  --error-color: #dc3838;
  --valid-color: #5da854;

  // Fonts
  --font-color: var(--base);
  --title-font: general-sans, sans-serif;
  --main-font: inter, sans-serif;

  // Easings
  --ease-menu: cubic-bezier(0.65,0.05,0.36,1);
  --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  --ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  --ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  --ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  --ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
  --ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
  --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
  --ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
  --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
  --ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
  --ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
  --ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);

  --screen-height: 100vh;
  --page-transition: var(--grey-d1);
}

/* -----
Paths
----- */
$base-images: "../images/";
$base-fonts: "../fonts/";

/* -----
Grid
----- */
$base-vw: 1440;
$base-grid: 24;
$browser-context: 16;

/* -----
CSS Easings
----- */
// $transitionDuration : 600ms;
$ease: cubic-bezier(0.36, 0.33, 0, 1);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeSwiper: cubic-bezier(0.36, 0.33, 0, 1);

/* -----
Media Queries
----- */

$mobileSmall: 330px;
$mobile: 550px;
$tabletSmall: 768px;
$tablet: 992px;
$desk: 1200px;
$deskXL: 1460px;
$deskXXL: 1680px;
