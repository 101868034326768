.PrestationCard{
  @include noTouchHover() {
    .inner{
      transform: scale(0.95);
    }

    .visual__container{
      transform: scale(1.05);
    }
  }

  .wrapper{
    position: relative;
    z-index: 1;

    @include responsive($tabletSmall, max){
      padding: 3em;
    }

    @include responsive($tabletSmall){
      padding: get-vw(60);
    }

    .inner{
      transition: transform 800ms var(--ease-out-quart);

      > span{
        font: 500 22px var(--title-font);
        display: block;

        @include responsive($tabletSmall){
          @include font-vw(32);
        }

        @include responsive($deskXL){
          font-size: 32px;
        }
      }
    }
  } 

  .visual__container{
    @include imgRatio(640, 340);
    transition: transform 800ms var(--ease-out-quart);
    z-index: 0;
  }
}
