.OfferContentBlock{
  @include flex();
  position: relative;

  & + .OfferContentBlock{
    @include responsive($tabletSmall, max){
      margin-top: 10em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(90);
    }
  }

  &:nth-child(odd){
    .visual__container{
      @include responsive($tabletSmall, max){
        right: 0;
      }

      @include responsive($tabletSmall){
        margin-left: auto;
      }
    }
  }

  &:nth-child(even){
    @include responsive($tabletSmall){
      flex-direction: row-reverse;
    }

    .wrapper{
      @include responsive($tabletSmall, max){
        margin-left: auto;
      }
    }

    .visual__container{
      @include responsive($tabletSmall, max){
        left: 0;
      }

      @include responsive($tabletSmall){
        margin-right: auto;
      }
    }
  }

  > *{
    @include responsive($tabletSmall){
      max-width: space(9);
    }
  }

  .wrapper{
    @include flex(flex-start);
    flex-direction: column;

    @include responsive($tabletSmall, max){
      max-width: 85%;
    }

    @include responsive($tabletSmall){
      padding: get-vw(80) 0;
    }

    h2{
      br{
        display: none;
      }
    }

    .Btn{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(30);
      }
    }
  }

  .visual__container{
    @include responsive($tabletSmall, max){
      @include imgRatio(540, 690);
      position: absolute;
      max-width: 50%;
      top: -10%;
      z-index: -1;
      opacity: 0.2;
    }
  }
}