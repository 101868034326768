.Intro {
  @include cover(fixed);
  z-index: 2000;
  transform: translateY(-100%);
  background: var(--base);
  visibility: hidden;
  overflow: hidden;

  html.--dark & {
    // background: darken(var(--base), 5%);
    background: var(--black);
  }

  body.--animating & {
    visibility: visible;
  }

  .inner {
    @include cover();
    @include flex(center, center);
  }
}
