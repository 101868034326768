.RealisationCard{
  grid-column: span 4;

  @include responsive($tabletSmall, max){
    height: 400px;
  }

  @include responsive($mobile){
    grid-column: span 2;
  }

  @include responsive($tabletSmall){
    grid-column: span 1;
    height: get-vw(400);
  }

  @include noTouchHover() {
    > .visual__container{
      transform: scale(1.05);
    }

    > .inner{
      .wrapper{
        transform: scale(0.95);
      }
    }
  }
  
  &, > .inner{
    position: relative
  }

  > .inner{
    @include flex();
    flex-direction: column;
    height: 100%;
    z-index: 1;
    padding: 1em 1em 0 1em;

    .wrapper{
      margin-top: auto;
      padding: 1.5em;
      transform-origin: 50% 100%;
      transition: transform 800ms var(--ease-out-quart);
    }
  }

  > .visual__container{
    @include cover();
    z-index: 0;
    transition: transform 800ms var(--ease-out-quart);
  }
}