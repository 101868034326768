.JobOfferContent{
  padding-bottom: 70px;
  padding-top: 250px;
  
  @media (min-height: 650px) {
    padding-top: 35vh;
  }

  @include responsive($tabletSmall){
    padding-bottom: get-vw(70);
  }

  .head{
    @include flex(center, center);
    flex-direction: column;
    position: relative;

    @include responsive($tabletSmall, max){
      margin-bottom: 3em;
    }

    @include responsive($tabletSmall){
      margin-bottom: get-vw(65);
    }

    h1{
      margin: 0.5em 0;
      text-align: center;
    }

    .Sharing{
      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        position: absolute;
        top: 0; left: 0;
      }
    }
  }

  .content{
    @include flex(center);
    flex-direction: column;
    
    @include responsive($tabletSmall){
      max-width: space(10);
      margin: 0 auto;
    }

    .Btn{
      @include responsive($tabletSmall, max){
        margin-top: 2.5em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(50);
      }
    }
  }
}