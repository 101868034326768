.QuotationForm {
  @include responsive($tabletSmall){
    margin-top: -5%;
  }

  &:not(.--show-all-form){
    .all-form{
      display: none;
    }
  }

  &:not(.--show-hidden-form-groups){
    .hidden-form-groups{
      display: none;
    }
  }

  .hidden-form-groups,
  .preferences{
    @include responsive($tabletSmall, max) {
      margin: 2em 0;
    }

    @include responsive($tabletSmall) {
      margin: get-vw(34) 0;
    }

    .mid-title {
      margin-bottom: 1em;
    }
  }

  .preferences{
    .wrapper{
      @include flex(flex-start);

      @include responsive($mobile, max){
        flex-direction: column;
      }

      > * {
        &:not(:last-child) {
          @include responsive($mobile, max){
            margin-bottom: 1em;
          }

          @include responsive($mobile){
            margin-right: 25%;
          }

          @include responsive($tabletSmall) {
            margin-right: get-vw(150);
          }
        }
      }
    }
  }

  .preferences-block {
    @include flex(flex-start);
    flex-direction: column;

    &.--frequencies,
    &.--type {
      margin-top: 1em;
    }

    > span {
      margin-bottom: 1em;

      sup {
        color: var(--green);
      }
    }

    span.--error{
      position: relative;
      inset: initial;
      margin: 2em 0;
      flex: 1 0 100%;
      max-width: 100%;
    }
  }

  .hidden-form-groups{
    margin: 2em 0;
  }
}
