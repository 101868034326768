.Sponsorship{
  > .container{
    @include flex(center, center);
    flex-direction: column;
  }

  h2, .wswyg--content{
    color: var(--white);
    text-align: center;
  } 

  .Btn{
    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(40)
    }
  }
}