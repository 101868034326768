.QuotationSteps{
  > .container{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }
  }

  .head{
    @include responsive($tabletSmall){
      position: sticky;
      top: 10%;
      max-width: space(10);
    }

    h2{
      color: var(--green-l1);

      @include responsive($tabletSmall, max){
        margin-bottom: 0;
      }
      
      @include responsive($tabletSmall){
        margin-bottom: 0.5em;
      }

      strong{
        color: var(--black);
      }
    }
  }

  .steps{
    @include responsive($tabletSmall, max){
      margin-top: 5em;
    }

    @include responsive($tabletSmall){
      max-width: space(9);
      margin-right: space(1);
      margin-left: auto;
    }
  }

}