.NavBrand{
  position: relative;
  z-index: 1;
  pointer-events: all;

  &.--mobile{
    @include responsive($menuBreakpoint){
      display: none;
    }

    .Logo{
      @include responsive($menuBreakpoint, max){
        width: get-vw(100);
        min-width: 80px;
      }

      body.--scrolled .Header.--visible &{
        width: get-vw(65);
        min-width: 65px;
      }
    }
  }

  &.--desktop{
    @include responsive($menuBreakpoint, max){
      display: none;
    }
    
    @include responsive($menuBreakpoint){
      order: 2;
    }

    .Logo{
      width: get-vw(120);

      body.--scrolled .Header.--visible &{
        width: get-vw(70);
      }
    }
  }

  .Logo{
    transition: width 400ms var(--ease-menu) 0ms,
                min-width 400ms var(--ease-menu) 0ms,
                transform 400ms var(--ease-menu) 0ms;

    @include noTouchHover() {
      transform: scale(0.95);
    }

    svg *{
      transition: fill 400ms var(--ease-menu) 50ms;
    }

    svg > circle{
      fill: var(--white);

      body.--show-submenu &{
        @include responsive($menuBreakpoint){
          fill: var(--black);
        }
      }
    }

    svg path {
      fill: var(--black);

      body.--show-submenu &{
        @include responsive($menuBreakpoint){
          fill: var(--white);
        }
      }
    }
  }
}

.NavContainer{
  position: relative;
  pointer-events: all;
  
  @include responsive($menuBreakpoint, max){
    transition: opacity 400ms var(--ease-menu);
  }
  
  @include responsive($menuBreakpoint){
    align-items: stretch;
  }

  body.--animating &{ pointer-events: none }

  body:not(.--show-menu) &{
    @include responsive($menuBreakpoint, max){
      opacity: 0; 
      pointer-events: none
    }
  }

  @include responsive($menuBreakpoint, max){
    @include cover(fixed);
    background: var(--base);
    padding: 15em 4vw 0;
    overflow-y: scroll;
  }

  &, > ul{
    @include flex();

    @include responsive($menuBreakpoint, max){
      align-items: flex-start;
      flex-direction: column;
    }
  }
  
  > ul{
    @include responsive($menuBreakpoint, max){
      gap: 1.5em;
      width: 100%;
    }

    @include responsive($menuBreakpoint){
      flex: 1;
      align-items: center;
      gap: get-vw(20);
    }

    &.--links-with-submenus{
      @include responsive($menuBreakpoint){
        order: 1;
      }
    }

    &:not(.--links-with-submenus){
      @include responsive($menuBreakpoint, max){
        margin-top: 1.5em;
      }
      
      @include responsive($menuBreakpoint){
        justify-content: flex-end;
        order: 3;
      }
    }
  }
}

.NavItem{
  position: relative;

  @include responsive($menuBreakpoint, max){
    width: 100%;
  }

  &:first-child{
    .NavSubmenu{
      background: #1E1E1D;
    }
  }

  &:nth-child(2){
    .NavSubmenu{
      background: #272726;
    }
  }

  > .Btn.--secondary{
    @include responsive($menuBreakpoint, max){
      background: rgba(255, 255, 255, 0.2);
      color: var(--white);
    }   

    &, 
    body.--scrolled .Header.--visible.--light &{
      @include responsive($menuBreakpoint){
        background: rgba(0, 0, 0, 0.05);
        color: var(--black);
      }
    }

    body.--show-submenu .Header &,
    body.--show-submenu .Header.--visible &,
    body.--scrolled.--show-submenu .Header.--visible &,
    .Header.--light &{
      @include responsive($menuBreakpoint){
        background: rgba(255, 255, 255, 0.2);
        color: var(--white);
      }
    }

    .icons{
      .Header.--light &{
        background: var(--white);

        .Icon[data-name="arrow-small"] svg > *{ stroke: var(--black); }
        .Icon[data-name="bubble"] svg > *{ stroke: var(--black); }
      }
    }
  }
}

.NavLink{
  font: 500 18px var(--title-font);
  position: relative;
  z-index: 1;
  transition: color 400ms var(--ease-menu);

  @include responsive($menuBreakpoint, max){
    color: var(--white);
    width: 100%;
  }

  @include responsive($menuBreakpoint){
    @include font-vw(16);
  }

  @include responsive($deskXL){
    font-size: 16px;
  }

  body.--scrolled .Header.--visible.--light &{
    @include responsive($menuBreakpoint){
      color: var(--black);
    }

    span{
      @include responsive($menuBreakpoint){
        background-image: linear-gradient(var(--black), var(--black));
      }
    }
  }

  body.--show-submenu .Header.--light &,
  body.--scrolled.--show-submenu .Header.--visible.--light &,
  body.--show-submenu .Header:not(.--light) &{
    @include responsive($menuBreakpoint){
      color: var(--white);
    }

    span{
      @include responsive($menuBreakpoint){
        background-image: linear-gradient(var(--white), var(--white));
      }
    }
  }

  .Header:not(.--light) &{
    @include responsive($menuBreakpoint){
      color: var(--black);
    }

    span{
      @include responsive($menuBreakpoint){
        background-image: linear-gradient(var(--black), var(--black));
      }
    }
  }

  .Header.--light &{
    @include responsive($menuBreakpoint){
      color: var(--white);
    }

    span{
      @include responsive($menuBreakpoint){
        background-image: linear-gradient(var(--white), var(--white));
      }
    }
  }
}

.NavLinkSubmenu{
  @include flex(flex-end);

  .Icon[data-name="chevron"]{
    transition: transform 400ms var(--ease-menu) 20ms;
    
    @include responsive($menuBreakpoint, max){
      transform: rotate(-90deg);
      min-width: 22px;
      width: get-vw(15);
      margin-left: 0.25em;
    }
    
    @include responsive($menuBreakpoint){
      min-width: 10px;
      width: get-vw(20);
    }

    svg > *{
      transition: stroke 400ms var(--ease-menu) 20ms;

      body.--scrolled .Header.--visible.--light &{
        @include responsive($menuBreakpoint){
          stroke: var(--black);
        }
      }

      body.--scrolled.--show-submenu .Header.--visible.--light &,
      .Header.--light &{
        @include responsive($menuBreakpoint){
          stroke: var(--white);
        }
      }

      @include responsive($menuBreakpoint, max){
        stroke: var(--white);
        opacity: 0.25;
      }
    }
  }

  body.--show-submenu &{
    .Icon[data-name="chevron"] {
      svg > * {
        stroke: var(--white);
      }
    }
  }

  .NavItem.--show-submenu &{
    .Icon[data-name="chevron"] {
      @include responsive($menuBreakpoint){
        transform: scaleY(-1);
      }
    }
  }
}

.NavSubmenu{
  position: fixed;
  width: 100%;
  top: 0; left: 0;
  z-index: 0;
  transition: clip-path 650ms var(--ease-menu);
  pointer-events: none;
  
  @include responsive($menuBreakpoint, max){
    height: var(--screen-height);
    clip-path: inset(0% 100% 0% 0%);
    padding: 5em 0 2.5em;
    z-index: 100;
    overflow-y: scroll;
  }
  
  @include responsive($menuBreakpoint){
    z-index: -1;
    clip-path: inset(0% 0% 100% 0%);
    padding: get-vw(250) 0 get-vw(50);
  }

  body.--scrolled &{
    @include responsive($menuBreakpoint){
      padding: get-vw(150) 0 get-vw(50);
    }
  }

  .NavItem.--show-submenu &{
    clip-path: inset(0% 0% 0% 0%);
    pointer-events: all;

    @include responsive($menuBreakpoint){
      z-index: 0;
    }
  }

  > .container{
    @include flex();
    flex-direction: column;
  }

  .BackLink{
    @include responsive($menuBreakpoint, max){
      margin-bottom: 5em;
      align-self: flex-start;
    }

    @include responsive($menuBreakpoint){
      display: none;
    }
  }

  .swiper{
    width: 100%;
    
    @include responsive($menuBreakpoint){
      padding-left: get-vw(250);
    }

    &-slide{
      height: 220px;
      width: 220px;
      transform: translate(100%, 0);
      opacity: 0;

      @include responsive($tabletSmall){
        height: get-vw(220);
        width: get-vw(220);
      }

      @include responsive($tabletSmall){
        height: get-vw(220);
        width: get-vw(220);
      }

      @for $i from 1 to 50 {
        &:nth-child(#{$i}) { transition-delay: $i * 35ms !important; }
      }
      
      .NavItem.--show-submenu &{
        transform: translate(0, 0);
        opacity: 1;
      }
    }
  }

  .Btn{
    align-self: center;
    justify-self: center;
    text-transform: lowercase;
    
    @include responsive($menuBreakpoint, max){
      margin-top: 3.5em;
    }

    @include responsive($menuBreakpoint){
      margin-top: get-vw(30);
    }

    .text .line > div:first-child{
      text-transform: uppercase;
    }
  }
}
