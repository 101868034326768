.Numbers{
  .About &{
    padding-top: 0;
  }

  @include responsive($tabletSmall){
    padding-bottom: get-vw(150)
  }

  h2{
    font: 500 60px var(--title-font);
    filter: opacity(0.1);
    margin-bottom: 0;
    white-space: nowrap;

    @include responsive($mobile){
      @include font-vw(60);
    }

    @include responsive($tabletSmall){
      @include font-vw(220);
    }

    @include responsive($deskXL){
      font-size: 220px;
    }
  }

  .wrapper{
    @include flex(flex-start);
    flex-wrap: wrap;

    @include responsive($mobile, max){
      gap: 1em;
    }
  }
}