/* -----
Basic Mixin'
----- */

@mixin size($width, $height){
  width: $width;
  height: $height;
}

@mixin center-block(){
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin fluid(){
  display:block;
  max-width: 100%;
  height: auto;
}

@mixin content($content:''){
   content: $content;
   display: block;
}

@mixin imgRatio($width, $height, $bgColor: transparent) {
  &:before {
    content: '';
    display: block;
    background: $bgColor;
    padding-top: percentage(math.div($height,$width));
  }
}

@mixin cover($position: absolute){
   position: $position;
   top: 0; left: 0;
   width: 100%;
   height: 100%;
}

@mixin absolute($top:0, $right:0, $bottom:0, $left:0){
   position: absolute;
   top: $top; right: $right; bottom: $bottom; left:$left;
   margin: auto;
}

@mixin fixed($top:0, $right:0, $bottom:0, $left:0){
   position: fixed;
   top: $top; right: $right; bottom: $bottom; left:$left;
   margin: auto;
}

@mixin centered(){
   position: absolute;
   top:50%;
   left:50%;
   transform:translate(-50%, -50%);
}


/* -----
Responsive
----- */

@mixin responsive($width, $min:min, $orientation:null) {

  @if $orientation {
    @if $min == min {

      @media only screen and (min-width:$width) and (orientation: $orientation) {
        @content;
      }

    } @else if $min == max {

      @media only screen and (max-width:$width - 1px) and (orientation: $orientation) {
        @content;
      }
    }

  } @else {
    @if $min == min {

      @media only screen and (min-width:$width)  {
        @content;
      }

    } @else if $min == max {

      @media only screen and (max-width:$width - 1px)  {
        @content;
      }
    }
  }
}

@function get-vw($target) {
  $vw-context: ($base-vw*.01) * 1;
  @return math.div($target, $vw-context) * 1.0vw;
}

@mixin font-vw($target) {
  font-size: get-vw($target);
}

@function strip-unit($value) {
  @return math.div($value,($value * 0 + 1));
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}


/* -----
Grid
----- */

@function space($value) {
  @return math.div(100vw, $base-grid) * $value;
}

@mixin flex($align: stretch, $justify: flex-start, $display: flex, $wrap: nowrap) {
  display: $display;

  @if $align != 'stretch' { align-items: $align; }
  @if $justify != 'flex-start' { justify-content: $justify; }
  @if $wrap != 'nowrap' { flex-wrap: $wrap; }
}

@mixin grid($cols:12, $gutter:null) {

  display:grid;
  grid-template-columns:repeat($cols, 1fr);
  grid-row-gap:$gutter;
  grid-column-gap:$gutter;

  > *:not(.col) {
    grid-column: span $cols;
  }

  @include responsive($tablet, max) {
    > .col {
      grid-column: span $cols;
    }
  }

  @include responsive($tabletSmall) {
    @for $i from 1 through $cols {
      > .col-xs-#{$i} {
        grid-column: span #{$i};
      }
    }
  }

  @include responsive($tablet) {
    @for $i from 1 through $cols {
      > .col-sm-#{$i} {
        grid-column: span #{$i};
      }
    }
  }

  @include responsive($desk) {
    @for $i from 1 through $cols {
      > .col-l-#{$i} {
        grid-column: span #{$i};
      }
    }
  }

  @include responsive($deskXL) {
    @for $i from 1 through $cols {
      > .col-xl-#{$i} {
        grid-column: span #{$i};
      }
    }
  }

  @for $i from 1 through $cols {
    > .col-push-#{$i} {
      grid-column: span #{$i};
    }
  }
}


@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return math.div($pixels, $context) * 1em;
}


/* -----
Divers
----- */

@mixin clip-path($clip) {
  clip-path: $clip;
}

@mixin noPseudoElements(){
  &::before, &::after{
    display: none;
  }
}

@mixin rounded($radius){
   border-radius:$radius;
}

@mixin shadow($x, $y, $blur, $color){
   box-shadow: $x $y $blur $color;
}

@mixin gradient($from, $to){
   background:$to;
   background:linear-gradient(90deg, $from 0%, $to 100%);
}


/* -----
Hover
----- */

@mixin noTouchHover() {
  html:not(.touch) &:hover {
    @content;
  }
}

@mixin hover-color($color){
  &:hover{color: $color;}
}

@mixin hover-bg($color){
  &:hover{background-color: $color;}
}

/* -----
Background image
----- */
@mixin bg(){
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin imageCover() {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  object-fit:cover;
}
