.PageHeaderRealisations{
  padding-bottom: 70px;
  padding-top: 250px;
  
  @media (min-height: 650px) {
    padding-top: 35vh;
  }

  @include responsive($tabletSmall){
    padding-bottom: get-vw(70);
  }

  .wrapper{
    @include responsive($tabletSmall){
      max-width: space(12);
      margin: 0 auto;
    }
  }

  .wswyg--content{
    @include responsive($tabletSmall, max){
      margin-top: 3em;
    }

    @include responsive($tabletSmall){
      margin: get-vw(40) auto 0;
      max-width: get-vw(530);
    }
  }
}