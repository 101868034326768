.Logo{
  @include imgRatio(24, 24);
  position: relative;

  svg{
    @include cover();

    circle{ fill: var(--black) }
    path { fill: var(--white) }
  }

  .Footer &{
    position: absolute;
    top: 0; left: 50%;
    transform: translate(-50%, -50%);

    @include responsive($tabletSmall, max){
      width: 80px;
    }
    
    @include responsive($tabletSmall){
      width: get-vw(94);      
    }

    svg path {
      fill: var(--white);
    }
  }
}