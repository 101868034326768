.AccompanimentSteps{
  padding-bottom: get-vw(250);

  > .container{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }
  }

  .counter{
    @include flex(center, center);
    font: 500 150px var(--font-title);
    border-radius: 50%;
    color: var(--black);
    position: sticky;
    top: 1em; 

    @include responsive($tabletSmall, max){
      height: 100px;
      width: 100px;
    }

    @include responsive($tabletSmall){
      @include font-vw(150);
      height: get-vw(480);
      width: get-vw(480);
    }

    @include responsive($deskXL){
      font-size: 150px;
    }

    > .current{
      position: relative;
      text-align: right;

      span:not(:first-child){
        // display: none;
        position: absolute;
        top: 0; right: 0;
        opacity: 0;
        white-space: nowrap;
      }
    }

    > .total{
      &:before{
        content: '/';
        display: inline-block;
      }
    }

    > .circle{
      svg {
        @include cover();
        fill: none;
        transform: rotate(-90deg);
      }
    }
  }

  .steps{
    @include responsive($tabletSmall, max){
      max-width: 70%;
      margin-top: -20%;
      margin-left: auto;
    }

    @include responsive($tabletSmall){
      max-width: space(9);
      margin-right: space(1);
      margin-left: auto;
    }

    .step{
      padding: 2em 0;

      // Title
      > span{
        font: 500 40px var(--font-title);
        color: var(--black);
        margin-bottom: 0.5em;

        @include responsive($tabletSmall){
          @include font-vw(40);
        }

        @include responsive($deskXL){
          font-size: 40px;
        }
      }
    }
  }
}