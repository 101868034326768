.DynamicBanner {
  @include responsive($tabletSmall, max) {
    margin-top: 2em;
  }

  @include responsive($tabletSmall) {
    margin-top: get-vw(80);
    padding: 1.75em;
  }

  > .visual__container {
    @include imgRatio(1440, 640);
  }
}
