.PriceBlock{
  position: relative;
  width: 100%;

  @include responsive($tabletSmall, max){
    padding: 2em;
  }

  @include responsive($tabletSmall){
    max-width: 50%;
    padding: get-vw(30);
  }

  &:first-child{
    background: #356E85;
  }

  &:nth-child(2){
    background: var(--blue);
  }

  .tag{
    font-weight: 600;
    
    @include responsive($tabletSmall, max){
      margin-bottom: 1.25em;
    }
    
    @include responsive($tabletSmall){
      position: absolute;
      top: 0; left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
    }
  }

  .inner{
    @include flex(flex-start);
    flex-direction: column;
  }

  .subtitle{
    font: 500 12px var(--main-font);

    @include responsive($tabletSmall){
      @include font-vw(12);
    }

    @include responsive($deskXL){
      font-size: 12px;
    }
  }

  .price{
    &:not(.mid-title){
      font: 500 18px var(--main-font);

      @include responsive($tabletSmall){
        @include font-vw(18);
      }

      @include responsive($deskXL){
        font-size: 18px;
      }
    }
  }
}