.BackLink{
  display: inline-flex;
  align-items: center;

  @include noTouchHover() {
    > div{
      transform: scale(0.8);

      .Icon{
        &:first-child{
          transform: translate(-200%, 0%) scaleX(-1);
        }

        &:nth-child(2){
          transform: translate(-50%, -50%) scaleX(-1);
        }
      }
    }

    > span:before{
      transform: scaleX(1);
      transform-origin: 0% 0%;
    }
  }

  > div{
    @include flex(center, center);
    height: get-vw(30);
    width: get-vw(30);
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    transition: transform 500ms var(--ease-in-out-circ);

    @include responsive($tabletSmall, max){
      min-width: 30px;
      min-height: 30px;
    }

    .ArchivePrestationHeader &{
      background: var(--white);

      .Icon svg > *{ stroke: var(--black-d1) }
    }

    .Icon{
      transform: scaleX(-1);
      transition: transform 500ms var(--ease-in-out-circ);

      &:nth-child(2){
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(120%, -50%) scaleX(-1);
      }
    }
  }

  > span{
    font: 500 15px var(--title-font);
    margin-left: 1em;
    position: relative;

    @include responsive($tabletSmall){
      @include font-vw(15);
    }

    @include responsive($deskXL){
      font-size: 15px;
    }

    .JobOfferContent &{
      color: var(--black);

      &:before{
        background: var(--green-l1);
      }
    }

    .ArchivePrestationHeader &{
      color: var(--white);

      &:before{
        background: #c6c5c5;
      }
    }

    &:before{
      @include content();
      position: absolute;
      top: 100%; left: 0;
      height: 1px;
      width: 100%;
      background: var(--grey-d1);
      transform: scaleX(0);
      transform-origin: 100% 100%;
      transition: transform 500ms var(--ease-in-out-circ);
    }
  }
}