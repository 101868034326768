.visual__container{
  &.--filter-gradient{
    z-index: 0;
    transition: transform 800ms var(--ease-out-quart);
        
    &, &:after{
      @include cover();
    }

    &:after{
      @include content();
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    }
  }
}

.visual {
  @include cover();
  transform: scale(1.02);
  transform-origin: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  will-change: transform;

  video {
    @include cover();
    object-fit: cover;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  &[data-bg] {
    @include cover();
    background-size: cover;
    background-position: center;
  }

  &__container {
    position: relative;
  	overflow: hidden;
  	width: 100%;

  	&:before {
  		@include content();
  	}
  }
}
