.ArchivePrestationPresentation{
  .wrapper{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    @include responsive($tabletSmall){
      margin: 0 space(1);
    }
  }

  h2{
    @include responsive($tabletSmall){
      max-width: space(8);
    }
  }

  .wswyg--content{
    @include responsive($tabletSmall, max){
      margin-top: 2.5em;
    }

    @include responsive($tabletSmall){
      max-width: space(9);
      margin-left: auto;
    }
  }
}