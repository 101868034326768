.RelatedRealisations{
  > .container{
    @include flex(center, center);
    flex-direction: column;

    > div{
      width: 100%;
    }
  }

  .swiper{
    &-wrapper{
      cursor: initial;

      @include responsive($tabletSmall){
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: get-vw(60) get-vw(20);
      }
    }

    &-slide{
      @include responsive($tabletSmall, max){
        height: 400px;
        width: 315px;
      }

      @include responsive($tabletSmall){
        height: get-vw(400);
      }
    }
  }

  .Btn{
    @include responsive($tabletSmall){
      margin-top: get-vw(55);
    }
  }
}