.Copyright {
   @include flex(center, center);

   .wkn {
      margin-left: 5px;

      &+span {
        margin-left: 5px;
        font-style: italic;
        font-family: serif;
      }

      svg {
        fill: var(--white);
      }
   }

   @include noTouchHover() {
     .wkn {
       opacity: 1;

       &+span {
         opacity: 1;
       }
     }
   }
}
