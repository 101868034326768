.TestimonialCard{
  height: 100%;

  @include responsive($tabletSmall, max){
    padding: 2em;
  }

  @include responsive($tabletSmall){
    padding: get-vw(60);
  }

  &, .wrapper{
    @include flex(flex-start);
    flex-direction: column;
  }

  .head{
    @include flex(flex-start, space-between);
    width: 100%;

    .stars{
      .visual__container{
        @include imgRatio(160, 32);
        width: get-vw(160);

        @include responsive($tabletSmall, max){
          min-width: 140px;
        }
      }
    }

    .platform{
      .Icon {
        &[data-name="facebook"]{
          width: get-vw(16);
        }

        svg > *{
          fill: var(--grey-d1);
        }
      }
    }
  }

  .wrapper{
    @include responsive($tabletSmall, max){
      margin-top: 3em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(40);
    }

    &, figure{
      flex: 1;
    }

    > span{
      font: 500 22px var(--title-font);
      
      @include responsive($tabletSmall, max){
        margin-bottom: 0.8em;
      }

      @include responsive($tabletSmall){
        @include font-vw(22);
        margin-bottom: get-vw(20);
      }

      @include responsive($deskXL){
        font-size: 22px;
      }
    }

    figure{
      @include flex(flex-start);
      flex-direction: column;

      blockquote{
        @include responsive($tabletSmall, max){
          padding-bottom: 3em;
        }
        
        @include responsive($tabletSmall){
          padding-bottom: get-vw(30);
        }
      }

      figcaption{
        font: 600 12px var(--title-font);
        margin-top: auto;

        @include responsive($tabletSmall){
          @include font-vw(12);
        }

        @include responsive($deskXL){
          font-size: 12px;
        }
      }
    }
  }
}