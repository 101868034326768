.Partnership{
  > .container{
    @include flex();

    @include responsive($tabletSmall, max){
      align-items: center;
      flex-direction: column;
    }

    > * {
      width: 100%;
    }
  }

  h2{
    @include responsive($tabletSmall, max){
      text-align: center;
    }

    @include responsive($tabletSmall){
      max-width: space(8);
      margin-left: space(1);
    }

    &.highlight.--neg.--green-d{
      strong:before{
        transform: scaleX(1);
      }
    }
  }

  .wrapper{
    @include flex(center);
    flex-direction: column;

    @include responsive($tabletSmall){
      align-items: flex-start;
      max-width: space(7);
      margin-right: space(1);
      margin-left: auto;
    }

    .wswyg--content{
      @include responsive($tabletSmall, max){
        text-align: center;
      }
    }
  }
}