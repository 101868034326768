.NavToggle {
  all: unset;
  @include flex(center, center);
  position: relative;
  padding: 0.25em;
  z-index: 10;
  pointer-events: all;
  cursor: pointer;
  height: get-vw(100);
  width: get-vw(100);
  min-height: 50px;
  min-width: 50px;

  @include responsive($menuBreakpoint) {
    display: none;
  }

  .line {
    display: block;
    height: 2px;
    width: 20px;
    background: var(--black);
    transition: transform 400ms var(--ease-menu),
                background 400ms var(--ease-menu) 150ms;

    body.--show-menu &{
      @include responsive($menuBreakpoint, max){
        background: var(--white);
      }
    }

    .Header.--light &{
      background: var(--white);
    }

    body.--scrolled .Header.--light &{
      background: var(--black);
    }
     
    body.--show-menu.--scrolled .Header.--light &{
      background: var(--white);
    }

    &:first-child,
    &:last-child{
      position: absolute;
      top: 50%; left: 50%;
      transform-origin: 50% 50%;
    }

    &:first-child{
      transform: translate(-50%, -0.45em);

      body.--show-menu &{
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    &:nth-child(2) {
      transform-origin: 0% 100%;
      
      body.--show-menu &{
        transform: scaleX(0);
        transform-origin: 100% 0%;
      }
    }

    &:last-child{
      transform: translate(-50%, 0.35em);

      body.--show-menu &{
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}
