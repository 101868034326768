.PageHeaderQuotation{
  position: relative;

  > .visual__container{
    position: sticky;
    height: var(--screen-height);
    width: 100%;
    top: 0;
    z-index: 0;
  
    &:after{
      @include content();
      @include cover();
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 50.01%), linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  > .container{
    @include flex(flex-start);
    position: relative;
    z-index: 1;
    margin-top: -100vh;
    padding-top: 250px;
    padding-bottom: 150px;
    
    @media (min-height: 650px) {
      padding-top: 35vh;
      padding-bottom: 30vh;
    }

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    .head{
      @include responsive($tabletSmall){
        max-width: space(9);
        position: sticky;
        top: 10%;
      }

      h1{
        strong:before{
          width: 115%;
        }
      }

      .wswyg--content{
        @include responsive($tabletSmall, max){
          margin-top: 2em;
        }

        @include responsive($tabletSmall){
          margin-top: get-vw(25);
          max-width: get-vw(430);
        }
      }
    }
  }
}
