.PrestationSimpleCard{
  @include flex(flex-end);
  background: var(--black);
  height: 100%;
  width: 100%;

  @include noTouchHover() {
    > .wrapper{
      transform: scale(0.95);
    }

    > .visual__container{
      transform: scale(1.05);
    }
  }

  .NavSubmenu &{
    > .wrapper{
      @include responsive($tabletSmall){
        padding: 1.3em;
      }
    }
  }

  &, > .wrapper{
    position: relative;
  }

  > .wrapper{
    z-index: 1;
        
    @include responsive($tabletSmall, max){
      padding: 30px;
    }

    @include responsive($tabletSmall){
      padding: 2em;
    }
        
    span{
      font: 500 22px var(--title-font);
      
      @include responsive($tabletSmall){
        @include font-vw(22);
        max-width: get-vw(150);
      }

      @include responsive($deskXL){
        font-size: 22px;
      }
    }
  }

  > .wrapper,
  > .visual__container{
    transition: transform 800ms var(--ease-out-quart);
  }
}
