.AllRealisations{
  > .container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    
    @include responsive($tabletSmall, max){
      grid-gap: 60px 20px;
    }

    @include responsive($tabletSmall){
      grid-gap: get-vw(60) get-vw(20);
    }
  }
}