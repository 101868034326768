.Speaker{
  @include flex(center);

  @include responsive($tabletSmall, max){
    flex-direction: column;
  }

  .visual__container{
    @include imgRatio(100, 100);
    width: get-vw(100);
    border-radius: 50%;
    
    @include responsive($tabletSmall, max){
      min-width: 100px;
      margin-bottom: 1em;
    }

    @include responsive($tabletSmall){
      margin-right: get-vw(30);
    }
  }

  .wrapper{
    @include flex(center, center);
    flex-direction: column;

    @include responsive($tabletSmall){
      align-items: flex-start;
    }

    > span{
      font: 500 22px var(--title-font);
      color: var(--black);
      margin-bottom: 0.35em;

      @include responsive($tabletSmall){
        @include font-vw(22);
      }

      @include responsive($deskXL){
        font-size: 22px;
      }
    }

    .speaker-link{
      position: relative;

      @include noTouchHover() {
        &:before,
        &:after{
          transform: scaleX(0);
          transform-origin: 100% 100%;
        }
      }

      &:before,
      &:after{
        @include content();
        position: absolute;
        top: 100%; left: 0;
        height: 1px; width: 100%;
        transform-origin: 0% 0%;
        background: var(--grey-d1);
        transition: transform 800ms var(--ease-in-out-circ);
      }
    }
  }
}