.ArchivePrestationHeader{
  padding-bottom: 70px;
  text-align: center;
  position: relative;
  padding-top: 250px;
  
  @media (min-height: 650px) {
    padding-top: 35vh;
  }

  @include responsive($tabletSmall){
    @include responsive($tabletSmall){
      padding-bottom: get-vw(70);
    }
  }

  .ArchivePrestationChild &{
    @include responsive($tabletSmall){
      padding-bottom: get-vw(120);
    }
  }


  > .container{
    @include flex(center, center);
    flex-direction: column;
    position: relative;
    z-index: 1;
  }

  .BackLink{
    margin-bottom: 2em;
  }

  h1{
    @include responsive($tabletSmall){
      max-width: 50%;
      margin: 0 auto;
    }

    &.--blue{
      strong:before{
        background: var(--blue-l1);
      }
    }
  }

  .wswyg--content{
    text-align: center;

    @include responsive($tabletSmall, max){
      margin-top: 3em;
    }

    @include responsive($tabletSmall){
      margin: get-vw(30) auto 0;
      max-width: get-vw(540);
    }
  }

  .visual__container{
    z-index: 0;

    &, &:after{
      @include cover();
    }

    &:after{
      @include content();
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
}
