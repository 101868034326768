.OfferCard{
  @include flex(center, center);
  flex-direction: column;
  width: 100%;
  transition: transform 450ms var(--ease-in-out-circ);

  @include responsive($tabletSmall, max){
    padding: 3.5em 1em;
    max-width: 300px;
  }

  @include responsive($tabletSmall){
    padding: get-vw(40) 1em;
    max-width: get-vw(300);
  }

  &:nth-of-type(2n+1){
    @include noTouchHover() {
      transform: scale(0.9) rotate(-2deg)
    }
  }

  &:nth-of-type(2n+2){
    @include noTouchHover() {
      transform: scale(0.9) rotate(2deg)
    }
  } 

  > span{
    font: 600 12px var(--main-font);
    line-height: 1;

    @include responsive($tabletSmall){
      @include font-vw(12);
    }

    @include responsive($deskXL){
      font-size: 12px;
    }

    strong{
      font: 600 32px var(--title-font);
      display: block;

      @include responsive($tabletSmall){
        @include font-vw(32);
      }

      @include responsive($deskXL){
        font-size: 32px;
      }
    }
  }

  > .visual__container{
    @include imgRatio(100, 100);
    width: get-vw(100);

    @include responsive($tabletSmall, max){
      min-width: 100px;
      margin-bottom: 1em;
    }

    @include responsive($tabletSmall){
      margin-bottom: get-vw(30);
    }

    img{
      width: 100%;
      height: auto;
      object-fit: initial;
    }
  }

  > .Btn{
    background: none;

    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(60);
    }
  }
}
