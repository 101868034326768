.PageHeaderRealisation{
  padding-top: 250px;
  
  @media (min-height: 650px) {
    padding-top: 35vh;
  }

  .head{
    @include flex(center, center);
    flex-direction: column;

    @include responsive($tabletSmall){
      max-width: space(10);
      margin: 0 auto;
    }

    h1{
      @include responsive($tabletSmall, max){
        margin: 1em 0 0.5em;
      }

      @include responsive($tabletSmall){
        margin: get-vw(50) 0 get-vw(20);
      }
    }
  }

  .Sharing{
    position: relative;
    z-index: 2;

    @include responsive($tabletSmall, max){
      @include flex(center, center);
      margin: 2em 0 4em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(80);
      max-width: space(5);
    }
  }

  .swiper{
    @include responsive($tabletSmall){
      margin-top: get-vw(-50);
    }
    
    &-slide{
      &.--portrait{
        max-width: get-vw(420);
      }

      &.--landscape{
        max-width: get-vw(1020);
      }

      > .visual__container{
        height: 100%;
        width: auto;
      }
    }

    &-navigation{
      order: 1;

      @include responsive($tabletSmall, max){
        margin: 0 0 2em;  
      }
      
      @include responsive($tabletSmall){
        margin: 0 0 get-vw(24);
      }
    }
  }
}