.PageHeaderPrestation{
  padding-bottom: 70px;
  padding-top: 250px;
  
  @media (min-height: 650px) {
    padding-top: 35vh;
  }

  @include responsive($tabletSmall){
    padding-bottom: get-vw(70);
  }

  .wrapper{
    @include flex(center, center);
    flex-direction: column;
  }

  h1{
    margin-top: 0.7em;

    @include responsive($tabletSmall){
      max-width: space(12);
    }
  }

  .wswyg--content{
    @include responsive($tabletSmall, max){
      margin: 2em auto 0;
    }

    @include responsive($tabletSmall){
      max-width: get-vw(540);
      margin: get-vw(30) auto 0;
    }
  }
}