.PageHero{
  height: 100vh;
  height: var(--screen-height);
  position: relative;
  
  &.--home{
    background: var(--black);
    
    .words{
      @include cover(fixed);
      @include flex(center, center);
      flex-direction: column;
      font: 500 22vw var(--title-font);
      line-height: 0.8;
      z-index: 100;
      pointer-events: none;

      body:not(.--show-words) &{
        display: none;
      }

      @include responsive($tabletSmall){
        @include font-vw(300);
      }

      @include responsive($deskXL){
        font-size: 300px;
      }
    }
  }

  > .container{
    @include flex(center, center);
    flex-direction: column;
    height: 100%;
    z-index: 1;
    padding-top: get-vw(70);
    padding-bottom: get-vw(70);
  }

  > .visual__container{
    &, &:after{
      @include cover();
    }

    &:after{
      @include content();
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  h1{
    @include responsive($tabletSmall, max){
      text-align: center;
    }
  }
}
