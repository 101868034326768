.CheckingListItem{
  @include flex(flex-start);

  > .Icon{
    margin-top: 0.2em;
  }

  > span{
    margin-left: 0.75em;
  }
}