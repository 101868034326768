.AllRealisationsNav{
  > .container{
    @include flex(center);
  }

  .swiper{
    width: 100%;
    padding: 0 20%;

    &-wrapper{
      cursor: initial;
    }

    &-slide{
      width: auto;
    }
  }

  // ul{
  //   @include flex(flex-start, flex-start);
  //   gap: 2em;
  // }

  a{
    font: 500 18px var(--title-font);
    display: inline-block;
    position: relative;
    padding: 1.5em 0;
    transition: filter 500ms var(--ease-in-out-circ);

    @include responsive($tabletSmall){
      @include font-vw(18);
    }

    @include responsive($deskXL){
      font-size: 18px;
    }

    &:not(.--active){
      filter: opacity(0.5);

      &:after{
        transform: scaleX(0);
        transform-origin: 100% 100%;
      }
    }

    &:after{
      @include content();
      position: absolute;
      bottom: 0; left: 0;
      height: 4px;
      width: 100%;
      background: var(--white);
      transform-origin: 0% 0%;
      transition: transform 500ms var(--ease-in-out-circ);
    }
  }
}