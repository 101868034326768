.PageHeaderOffer{
  padding-bottom: 70px;
  padding-top: 250px;
  
  @media (min-height: 650px) {
    padding-top: 35vh;
  }

  @include responsive($tabletSmall){
    padding-bottom: get-vw(70);
  }

  .wrapper{
    @include flex(center, center);
    flex-direction: column;

    @include responsive($tabletSmall){
      max-width: space(12);
      margin: 0 auto;
    }
  }

  h1, .wswyg--content{
    text-align: center;
  }

  h1{
    color: var(--black);

    @include responsive($tabletSmall, max){
      margin-bottom: 2em;
    }

    @include responsive($tabletSmall){
      margin-bottom: get-vw(34);
    }
  }

  .wswyg--content{
    @include responsive($tabletSmall){
      max-width: get-vw(540);
      margin: 0 auto;
    }
  }

  .Btn{
    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(30);
    }
  }

  .logo{
    @include imgRatio(100, 100);
    width: get-vw(100);
    min-width: 100px;

    @include responsive($tabletSmall, max){
      margn-bottom: 2em;
    }

    @include responsive($tabletSmall){
      margin-bottom: get-vw(30);
    }

    img{
      height: auto;
      width: 100%;
      object-fit: initial;
    }
  }
}