.Checkbox {
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;

  & + .Checkbox{
    @include responsive($tabletSmall, max){
      margin-top: 1em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(10);
    }
  }

  input {
    @include cover();
    margin: 0;
    opacity: 0;
    z-index: 10;
    cursor: pointer;

    &:checked {
      & + .Checkbox__custom {
        background: var(--base);
        border-color: var(--base);

        &:after {
          transform: scale(1);
        }

        svg {
          opacity: 1;
        }
      }
    }
  }

  &__custom {
    @include flex(center, center);
    border: 1px solid var(--grey-d1);
    margin-right: 10px;
    transition: background 240ms var(--ease-out-circ),
                border-color 240ms var(--ease-out-circ);

    @include responsive($tabletSmall){
      width: get-vw(24);
      height: get-vw(24);
    }

    // .--radio & {
    //   border-radius: 50%;

    //   &:after {
    //     @include content;
    //     width: get-vw(12);
    //     height: get-vw(12);
    //     background: var(--white);
    //     border-radius: 50%;
    //     transform: scale(0);
    //     transition: transform 240ms $easeOutQuad;
    //   }
    // }

    .Icon[data-name="tick"] {
      position: relative;

      &:before {
        @include content();
        padding-top: percentage(math.div(25, 33));
      }

      svg {
        overflow: visible;
        @include cover();
        opacity: 0;

        > * {
          &:last-child{
            stroke: var(--white);
            stroke-width: 2px;
          }
        }
      }
    }
  }

  span.--error{
    position: relative;
    inset: initial;
    margin: 2em 0;
    flex: 1 0 100%;
    max-width: 100%;
  }
}
