@import url("../../node_modules/plyr/dist/plyr.css");

.plyr {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  cursor: pointer;

  &__container {
    position: relative;
    background: var(--base);
    overflow: hidden;

    @include noTouchHover(){

      .plyr__cover {
        transform: scale(1.02);
      }

      .plyr__play:before {
        transform: scale(1.2);
      }
    }

    > video {
      @include cover();
    }
  }

  &__placeholder {
    padding-top: 56.25%;
    transition: padding 600ms $ease;
  }

  &__video-embed {
    @include cover();
    padding: 0;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }

  &__cover {
    @include cover();
    background: var(--base);
    cursor: pointer;
    transition: opacity 600ms $ease 50ms, transform 600ms $ease 0ms;

    .--dark & {
      background: var(--black);
    }

    .visual {
      transition: opacity 400ms linear;
    }
  }

  &__poster {
    opacity: 0;
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15%;
    max-width: 100px;
    border: 0;
    outline: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    transition: all 600ms $ease 0ms;
    pointer-events: none;

    &:before {
      @include content();
      background: var(--links-color);
      padding-top: 100%;
      border-radius: 100%;
      transition: transform 600ms $ease 0ms;
    }

    .Icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-40%, -50%);
      z-index: 10;
      min-width: 25%;
      width: 25%;

      svg > * {
        fill: var(--white);
      }
    }
  }

  &--paused:not(.plyr--seeking) {
    .plyr__controls { transform: translateY(100%)!important; transition-delay: 150ms; }
  }

  &--playing, &--seeking {
    & + .plyr__cover {
      pointer-events: none;
      opacity: 0!important;

      & + .plyr__play {
        pointer-events: none;
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.2);

        & + .plyr__placeholder {
          padding-top: 56.25%;
        }
      }
    }
  }

  &__controls { padding: 15px 20px!important; background:var(--base)!important; opacity: 1!important;}
  &__controls svg { width: 14px!important; height: 14px!important;}
  &__controls__item:not(:first-child) { margin-left: 10px!important; }
  &__control:not(.plyr__control--overlaid):hover { opacity:0.8; background:transparent!important; }
  &__progress input[type=range] { color: var(--green); height: 21px!important; margin-bottom: -5px; margin-top: -5px;}
  &__progress input[type=range]::-webkit-slider-thumb { margin-top: -6px!important; }
  &__progress input[type=range]::-webkit-slider-runnable-track { height: 1px!important; }
  &__progress__buffer { height: 1px!important; margin-top: 0px!important; transform: translateY(-50%); }
  &__volume {width:auto!important; min-width: 0px!important; }
  &__control .plyr__control--overlaid { display:none; }
}
