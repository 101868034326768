.Jardicoop{
  .wrapper{
    background: var(--green-l2);

    @include responsive($tabletSmall, max){
      padding: 4em 0;
    }

    @include responsive($tabletSmall){
      padding: get-vw(100) 0;
    }
  }

  .inner{
    @include flex(center, center);
    flex-direction: column;
    margin: 0 auto;

    @include responsive($tabletSmall, max){
      max-width: 90%;
    }
    
    @include responsive($tabletSmall){
      max-width: space(10);
    }
  }

  .visual__container{
    @include imgRatio(100, 100);
    width: get-vw(100);

    @include responsive($tabletSmall, max){
      min-width: 80px;
      margin-bottom: 1em;
    }

    @include responsive($tabletSmall){
      margin-bottom: get-vw(20);
    }
  }

  .wswyg--content{
    @include responsive($tabletSmall){
      max-width: get-vw(540);
    }
  }
}