.ProOffers{
  > .container,
  .wrapper{
    @include responsive($tabletSmall, max){
      flex-direction: column;
    }
  }

  > .container{
    @include flex(center);

    > *{
      width: 100%;
    }
  }

  .head{
    @include responsive($tabletSmall){
      max-width: space(7);
    }

    h2, .wswyg--content{
      @include responsive($tabletSmall, max){
        text-align: center;
      }
    }
  }

  .wrapper{
    @include flex();

    @include responsive($tabletSmall, max){
      align-items: center;
      margin-top: 2em;
      gap: 1.5em;
    }

    @include responsive($tabletSmall){
      max-width: space(11);
      margin-left: auto;
      gap: get-vw(30);
    }
  }
}