.RealisationDescription{
  > .container{
    @include flex(center);
    flex-direction: column;
  }

  .wswyg--content{
    // @include flex(center, center);
    // flex-direction: column;

    > * {
      margin-left: auto;
      margin-right: auto;

      @include responsive($tabletSmall){
        width: 100%;
      }

      &:not(:has(.plyr__container)){
        @include responsive($tabletSmall){
          width: space(10);
        }
      }

      &:has(.plyr__container){
        @include responsive($tabletSmall){
          width: space(14);
        }
      }
    }
  }

  .plyr__container{
    position: relative;
    min-height: 400px;

    @include responsive($tabletSmall, max){
      margin: 2em 0;
    }
    
    @include responsive($tabletSmall){
      height: get-vw(500);
      margin: get-vw(50) 0;
    }
  }

  .plyr__control.plyr__control--overlaid{
    @include flex(center, center);
    background: var(--black);
    opacity: 1;
    height: 80px;
    width: 80px;

    svg{
      fill: var(--white);
    }
  }

  .Btn{
    @include responsive($tabletSmall, max){
      margin-top: 5em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(100);
    }
  }
}
