$menuBreakpoint: $tabletSmall;

.Header {
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  z-index: 120;
  pointer-events: none;
  transition: transform 500ms var(--ease-menu),
              background 500ms var(--ease-menu);

  &:not(.--visible){
    body.--scrolled &{
      transform: translate(0, -100%);
    }
  }

  &.--visible{
    body.--scrolled &{
      background: var(--white);
    }
  }

  > .container {
    width: 100%;
    max-width: none;
    padding-top: 10px;
    padding-bottom: 10px;

    @include responsive($menuBreakpoint, max){
      @include flex(center, space-between);
    }

    &:after{
      @include responsive($menuBreakpoint){
        @include content();
        position: absolute;
        top: 100%; left: 0;
        height: 1px; width: 100%;
        background: #323232;
        transform: scaleX(0);
        transform-origin: 0% 0%;
        transition: transform 400ms var(--ease-menu) 0ms;
      }

      body.--show-submenu &{
        @include responsive($menuBreakpoint){
          transform: scaleX(1);
          transition: transform 400ms var(--ease-menu) 300ms;
        }
      }
    }
  }

  .NavBrand,
  .NavToggle{
    @include responsive($menuBreakpoint, max){
      transition: transform 400ms var(--ease-menu) 300ms,
                  opacity 400ms var(--ease-menu) 300ms;
    }

    body.--show-menu.--show-submenu &{
      transform: translate(0, -100%);
      opacity: 0;
      pointer-events: none;
      transition-delay: 0ms;
    }
  }
}
