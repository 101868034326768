.Testimonials{
  h2{
    font: 500 50px var(--title-font);
    color: var(--green-l1);
    line-height: 1;

    @include responsive($tabletSmall){
      @include font-vw(80);
    }

    @include responsive($deskXL){
      font-size: 80px;
    }

    strong{
      color: var(--black);
    }
  }

  .swiper{
    overflow: hidden;

    &-slide{
      width: 100%;

      @include responsive($tabletSmall, max){
        max-width: 90%;
      }
      
      @include responsive($tabletSmall){
        max-width: get-vw(540);
      }
    }
  }
}