.PageHeaderPatronage{
  padding-top: 250px;
  
  @media (min-height: 650px) {
    padding-top: 35vh;
  }

  @include responsive($tabletSmall, max){
    padding-bottom: 5em;
  }

  @include responsive($tabletSmall){
    padding-bottom: get-vw(100);
  }

  > .container{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    > *{
      width: 100%;
    }
  }

  .wrapper{
    @include responsive($tabletSmall){
      position: sticky;
      top: 10%;
      max-width: space(9);
    }

    h1{
      @include responsive($tabletSmall, max){
        margin-bottom: 1em;
      }

      @include responsive($tabletSmall){
        margin-bottom: get-vw(24);
      }
    }
  }

  .Form{
    .patronage-form-block {
      @include flex();
      flex-direction: column;

      & + .patronage-form-block{
        @include responsive($tabletSmall, max){
          margin-top: 3em;
        }

        @include responsive($tabletSmall){
          margin-top: get-vw(40);
        }
      }

      > span{
        font: 500 22px var(--title-font);
        
        @include responsive($tabletSmall, max){
          margin-bottom: 1.8em;
        }

        @include responsive($tabletSmall){
          @include font-vw(22);
          margin-bottom: get-vw(20);
        }

        @include responsive($deskXL){
          font-size: 22px;
        }
      }
    }
  }
}