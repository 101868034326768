.Number{
  color: var(--black);
  text-align: center;

  @include responsive($mobile, max){
    flex: 1 0 100%;
    max-width: 100%;
  }

  @include responsive($mobile){
    flex: 1 0 50%;
    max-width: 50%;
  }

  @include responsive($tabletSmall){
    flex: 1 0 25%;
    max-width: 25%;
  }

  .inner{
    padding: 1em;

    > span{
      font: 500 80px var(--title-font);

      @include responsive($tabletSmall){
        @include font-vw(130);
      }

      @include responsive($deskXL){
        font-size: 130px;
      }

      &.--percentage{
        &:after{
          content: '%';
          font: 500 32px var(--title-font);

          @include responsive($tabletSmall){
            @include font-vw(32);
          }

          @include responsive($deskXL){
            font-size: 32px;
          }
        }
      }
    }

    > p{
      font: 500 22px var(--title-font);

      @include responsive($tabletSmall){
        @include font-vw(22);
      }

      @include responsive($deskXL){
        font-size: 22px;
      }
    }
  }
}