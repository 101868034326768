.Partners{
  h2{
    text-align: center;

    @include responsive($tabletSmall){
      max-width: space(10);
      margin: 0 auto get-vw(50);
    }
  }

  .swiper{
    padding-left: 20%;

    @include responsive($tabletSmall){
      max-width: space(18);
      margin: 0 auto;
    }

    &-slide{
      @include imgRatio(250, 150);
      @include flex(center, center);
      max-width: 250px;
      background: var(--white);
      transition: transform 450ms var(--ease-in-out-circ);

      @include noTouchHover() {
        transform: scale(0.95);
      }

      .visual__container, .visual{
        @include cover();
      }

      .visual{
        padding: 2em;

        img{
          object-fit: contain;
        }
      }
    }
  }
}
