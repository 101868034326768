.FooterBlock{
  @include responsive($tabletSmall, max){
    grid-column: span 1;
  }

  @include responsive($mobile, max){
    grid-column: span 2;
  }

  &, ul{
    @include flex(flex-start);
    flex-direction: column;
  }

  .tag{
    margin-bottom: 2em;
  }

  ul{
    @include responsive($tabletSmall, max){
      gap: 20px;
    }

    @include responsive($tabletSmall){
      gap: get-vw(20);
    }
  }
}