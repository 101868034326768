.ArchivePrestationGrid{
  &.--sisters{
    h2{
      @include responsive($tabletSmall){
        max-width: space(9);
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .container > .wrapper{
    display: grid;

    @include responsive($tabletSmall, max){
      grid-template-columns: 1fr;
      grid-gap: 2em;
    }

    @include responsive($tabletSmall){
      grid-template-columns: repeat(2, 1fr);
      grid-gap: get-vw(40);
    }
  }
}