.Btn {
  display: inline-flex;
  align-items: center;
  background: var(--black);
  color: var(--white);
  border-radius: 20em;
  padding: 0.5em 1.5em 0.5em 0.5em;
  
  @include responsive($tabletSmall, max){
    margin-top: 2em;
  }

  @include responsive($tabletSmall){
    margin-top: get-vw(30);
  }

  @include noTouchHover() {
    > .icons{
      transform: scale(0.95);

      > *{
        &:first-child{
          transform: translate(2em, 0);
          opacity: 0;
        }

        &:nth-child(2){
          transform: translate(-50%, -50%);
          opacity: 1;
        }
      }
    }
    
    > .text{
      overflow: hidden;

      .line{
        &:first-child{
          transform: translate(0, -100%);
        }

        &:nth-child(2){
          transform: translate(0, 0);
        }
      }
    }
  }

  .NavContainer &{
    @include responsive($tabletSmall){
      margin-top: 0;
    }
  }

  &.--secondary{
    background: var(--white);
    color: var(--black);

    > .icons{
      background: var(--black);
    }
  }

  > .icons{
    @include flex(center, center);
    position: relative;
    height: get-vw(34);
    width: get-vw(34);
    min-height: 34px;
    min-width: 34px;
    border-radius: 50%;
    background: var(--text);
    transition: transform 350ms var(--ease-in-out-quart);
    
    > *{
      transition: opacity 350ms var(--ease-in-out-quart),
                  transform 350ms var(--ease-in-out-quart);

      &:nth-child(2){
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-2em, -50%);
        opacity: 0;
      }
    }
  }

  > .text{
    @include flex();
    margin-left: 0.7em;
    position: relative;
    overflow: hidden;
    
    .line{
      transition: transform 350ms var(--ease-in-out-quart);

      &::first-letter{
        text-transform: uppercase;
      }

      &:last-child{
        position: absolute;
        top: 0; left: 0;
        transform: translate(0, 100%);
      }
    }
  }
}