.Pagination{
  @include flex(center, center);
  
  @include responsive($tabletSmall, max){
    gap: 6px;
  }

  @include responsive($tabletSmall){
    gap: get-vw(6);
  }

  .AllRealisations &{
    grid-column: span 4;

    @include responsive($tabletSmall){
      margin-top: get-vw(30);  
    }
  }

  .bullet{
    @include flex(center, center);
    font: 500 14px var(--main-font);
    height: get-vw(36);
    width: get-vw(36);
    border-radius: 50%;

    @include responsive($tabletSmall){
      @include font-vw(14);
    }

    @include responsive($deskXL){
      font-size: 14px;
    }

    &:not(.--active){
      background: transparent;
      color: var(--black);
    }

    &.--active{
      background: var(--black);
      color: var(--white);
    }
  }
}