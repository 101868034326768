.PageHeader{
  padding-bottom: 70px;
  padding-top: 250px;
  
  @media (min-height: 650px) {
    padding-top: 35vh;
  }

  @include responsive($tabletSmall){
    padding-bottom: get-vw(70);
  }

  .container{
    @include flex();

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }
    
    h1{
      width: 100%;

      @include responsive($tabletSmall){
        max-width: space(11);
      }
    }

    .wrapper{
      @include flex(flex-start);
      flex-direction: column;

      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        max-width: space(9);
        margin-left: auto;
        margin-right: space(1);
      }

      .Btn{
        @include responsive($tabletSmall, max){
          margin-top: 2em;
        }

        @include responsive($tabletSmall){
          margin-top: get-vw(30);
        }
      }
    }
  }
}