.ContactInfos{
  @include flex(flex-start);
  flex-direction: column;

  .PageHeaderQuotation &{
    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(40);
    }
  }

  *{
    margin-bottom: 0.25em;
  }

  .--address{
    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(24);
    }
  }
}
