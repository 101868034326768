.ProductOffer {
  @include flex(flex-start);
  position: relative;

  & + .ProductOffer{
    @include responsive($tabletSmall, max){
      margin-top: 10em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(100);
    }
  }

  &:nth-child(odd) {
    > .wrapper {
      @include responsive($tabletSmall) {
        margin-right: space(2);
      }
    }

    > .visual__container{
      @include responsive($tabletSmall, max){
        right: 0;
      }
    }
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    > .wrapper {
      @include responsive($tabletSmall) {
        margin-left: space(2);
      }
    }

    > .visual__container{
      @include responsive($tabletSmall, max){
        left: 0;
      }
    }
  }

  > .wrapper,
  > .visual__container {
    @include responsive($tabletSmall) {
      max-width: space(9);
    }
  }

  > .wrapper {
    @include responsive($tabletSmall, max){
      max-width: 85%;
    }

    @include responsive($tabletSmall){
      padding: get-vw(30) 0;
    }

    h2{
      @include responsive($tabletSmall){
        margin-bottom: get-vw(30);
      }
    }

    ul {
      @include flex(flex-start);
      flex-direction: column;

      @include responsive($tabletSmall, max) {
        gap: 10px;
        margin-top: 2em;
      }

      @include responsive($tabletSmall) {
        gap: get-vw(10);
        margin-top: get-vw(30);
      }
    }

    .prices{
      @include flex();
      
      @include responsive($tabletSmall, max){
        flex-direction: column;
        margin-top: 2em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(50);
      }
    }
  }

  > .visual__container {
    @include imgRatio(540, 690);

    @include responsive($tabletSmall, max){
      position: absolute;
      max-width: 50%;
      top: -10%;
      z-index: -1;
      opacity: 0.2;
    }

    @include responsive($tabletSmall){
      position: sticky;
      top: 7%;
    }
  }
}
