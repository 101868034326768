.AboutPrestations{
  // .container > .wrapper{
  //   @include flex(center, center);
  //   flex-wrap: wrap;
  //   gap: 20px;
  // }

  .swiper{
    &-wrapper{
      @include responsive($tabletSmall){
        @include flex(center, center);
        flex-wrap: wrap;
        gap: get-vw(20);
        cursor: initial;
      }
    }

    &-slide{
      height: 310px;
      width: 310px;

      @include responsive($tabletSmall){
        height: get-vw(310);
        width: get-vw(310);
      }
    }
  }
}