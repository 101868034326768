.Loader {
  @include cover(fixed);
  z-index: 100;
  background: var(--white);
  visibility: hidden;
  overflow: hidden;

  html.--dark & {
    // background: darken($base, 5%);
    background: var(--black);
  }

  body.--intro & {
    visibility: hidden !important;
  }

  body.--animating & {
    visibility: visible;
  }
}
