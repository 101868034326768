.RealisationTestimonial{
  > .container{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;      
    }

    @include responsive($tabletSmall){
      align-items: center;
    }
  }

  > .container > .wrapper{
    @include responsive($tabletSmall){
      max-width: space(8);
      margin-left: space(1);
    }
  }

  h2{
    font: 500 40px var(--title-font);
    line-height: 1;
    color: var(--green-l1);
    margin-bottom: 0.5em;

    @include responsive($tabletSmall){
      @include font-vw(80);
    }

    @include responsive($deskXL){
      font-size: 80px;
    }

    > strong{
      color: var(--white);
    }
  }

  .TestimonialCardParent{
    @include responsive($tabletSmall, max){
      margin-top: 5em;
    }

    @include responsive($tabletSmall){
      max-width: get-vw(540);
      margin-left: auto;
    }
  }
}