.Engagements{
  .container > .head{
    @include responsive($tabletSmall, max){
      margin-bottom: 2em;
    }

    @include responsive($tabletSmall){
      max-width: space(10);
      margin: 0 auto get-vw(60);
    }

    h2, .wswyg--content{
      text-align: center;
    }
  }

  .swiper{
    &-slide{
      @include responsive($tabletSmall){
        max-width: get-vw(540);
      }
    }
  }
}