.RelatedPrestations{
  .swiper{
    &-wrapper{
      @include responsive($tabletSmall){
        cursor: initial;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: get-vw(40);
      }
    }

    &-slide{
      @include responsive($tabletSmall, max){
        max-width: 380px;
      }

      @include responsive($mobile, max){
        max-width: 90%;
      }
    }

    &-navigation{
      @include responsive($tabletSmall){
        display: none;
      }
    }
  }
}