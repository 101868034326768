.OfferSpeaker{
  .container > .wrapper{
    @include flex(center);

    @include responsive($tabletSmall, max){
      flex-direction: column;
      padding: 2em;  
    }

    @include responsive($tabletSmall){
      padding: get-vw(80) get-vw(200);
      align-items: flex-start;
    }
  }

  h2{
    @include responsive($tabletSmall, max){
      text-align: center;
    }
  }

  .Speaker{
    @include responsive($tabletSmall){
      margin-left: auto;
    }
  }
}