.JobInfos {
  > * {
    font: 600 12px var(--title-font);
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    padding: 0.5em 1em;
    color: var(--white);

    @include responsive($tabletSmall) {
      @include font-vw(12);
    }

    @include responsive($deskXL){
      font-size: 12px;
    }

    &:before {
      @include content();
      @include cover();
      z-index: 0;
    }

    > span {
      position: relative;
      z-index: 1;
    }

    &.--type {
      &:before {
        background: var(--blue-d1);
        transform: rotate(-1deg);
      }
    }

    &.--location {
      &:before {
        background: var(--blue);
        transform: rotate(1deg);
      }
    }
  }
}
