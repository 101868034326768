.Link {
  position: relative;
  
  span{
    display: inline;
    // background-image: linear-gradient(var(--base), var(--base));
    background-image: linear-gradient(var(--grey), var(--grey));
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 0% 1px;
    transition: background-size 600ms var(--ease-in-out-circ);
  }

  @include noTouchHover() {
    span{
      background-size: 100% 1px;
      background-position: 0% 100%;
    }
  }
}