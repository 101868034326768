.Intervention{
  
  > .container{
    @include flex();
    
    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    > *{
      @include responsive($tabletSmall, max){
        flex: 1 0 100%;
        max-width: 100%;
      }

      @include responsive($tabletSmall){
        flex: 1 0 50%;
        max-width: 50%;
      }
    }
  }

  .wrapper{
    @include flex(flex-start);
    flex-direction: column;
    padding: 5em 3em;

    &, h2{
      color: var(--white);
    }

    .Btn{
      @include responsive($tabletSmall, max){
        margin-top: 3em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(130);
      }
    }
  }

  .visual__container{
    @include responsive($tabletSmall, max){
      @include imgRatio(500, 500);
    }
  }
}
