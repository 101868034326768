.QuotationStep{
  @include flex(flex-start);

  & + .QuotationStep{
    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(64);
    }
  }

  .circle{
    position: relative;

    > span{
      font: 600 14px var(--title-font);
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);

      @include responsive($tabletSmall){
        @include font-vw(14);
      }

      @include responsive($deskXL){
        font-size: 14px;
      }
    }

    .Icon[data-name="step"]{
      transform: rotate(-90deg);
    }
  }

  .wrapper{
    margin-left: 1.5em;

    > span{
      font: 500 22px var(--title-font);
      margin-bottom: 0.6em;

      @include responsive($tabletSmall){
        @include font-vw(22);
      }

      @include responsive($deskXL){
        font-size: 22px;
      }
    }
  }
}
