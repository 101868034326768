.Team{
  .container{
    @include flex();

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    @include responsive($tabletSmall){
      padding: 0 space(3);
    }
    
    h2{
      width: 100%;

      @include responsive($tabletSmall){
        max-width: space(11);
      }
    }

    .wrapper{
      @include flex(flex-start);
      flex-direction: column;

      @include responsive($tabletSmall){
        max-width: space(9);
        margin-left: auto;
      }

      .Btn{
        @include responsive($tabletSmall, max){
          margin-top: 2em;
        }

        @include responsive($tabletSmall){
          margin-top: get-vw(30);
        }
      }
    }
  }
}