.Brand {
  .Intro &{
    overflow:hidden;

    svg > * {
      fill: var(--white);
    }
  }

  .Header &{
    position: relative;
    z-index: 10;
    pointer-events: all;
    margin-right: auto;

    svg > * {
      body.--show-menu & {
        @include responsive($menuBreakpoint, max) {
          fill: var(--white);
        }
      }
    }
  }
}