.JobOfferCard{
  padding: 2em;
  transition: transform 450ms var(--ease-in-out-circ);

  @include noTouchHover() {
    transform: scale(0.975);
  }

  & + .JobOfferCard{
    @include responsive($tabletSmall, max){
      margin-top: 1em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(20);
    }
  }

  &, .wrapper{
    @include flex(center);
  }

  .wrapper{
    @include responsive($tabletSmall, max){
      align-items: flex-start;
      flex-direction: column-reverse;
      gap: 1em;
    }
  }

  .title{
    font: 500 22px var(--title-font);
    color: var(--black);
    margin-right: 1.5em;

    @include responsive($tabletSmall){
      @include font-vw(22);
    }

    @include responsive($deskXL){
      font-size: 22px;
    }
  }

  .infos{
    @include flex(center);
    gap: 1em;
  }
}