.RelatedPrestationBanner{
  position: relative;
  padding-bottom: 0;

  > .container, .wrapper{
    @include flex(center, center);
    flex-direction: column;
  }

  .wrapper{
    @include responsive($tabletSmall, max){
      padding: 5em 0;
    }

    @include responsive($tabletSmall){
      max-width: space(14);
      padding: get-vw(220) 0;
    }

    > *{
      position: relative;
      z-index: 1;
    }

    h2{
      margin-bottom: 0;
    }
  }
}
