.NotFound{
  height: 100vh;
  height: var(--screen-height);

  > .PageH, 
  > .PageH .container{
    @include flex(center, center);
    flex-direction: column;
    height: 100%;
  }

  h1{
    font: 500 100px var(--title-font);

    @include responsive($tabletSmall){
      @include font-vw(100);
    }

    @include responsive($deskXL){
      font-size: 100px;
    }
  }
}