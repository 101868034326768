.OfferRelated{
  .wrapper{
    @include flex(center, center);
    flex-direction: column;

    @include responsive($tabletSmall){
      max-width: space(10);
      margin: 0 auto;
    }
  }

  h2{
    margin-bottom: 0;
  }

  .wswyg--content{
    @include responsive($tabletSmall){
      max-width: space(8);
    }
  }

  .wswyg--content,
  .OfferCard{
    @include responsive($tabletSmall, max){
      margin-top: 2.5em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(30);
    }
  }
}