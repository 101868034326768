.Socials{
  &.--dark{
    > ul{
      a, button{
        background: #383837;

        > span {
          color: var(--grey);
        }

        .Icon svg *{
          fill: var(--white);
        }
      }
    }
  }

  .PageHeaderQuotation &{
    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(35);
    }
  }

  > ul{
    @include flex();
    gap: 0.5em;

    a, button{
      @include imgRatio(40, 40);
      @include flex(center, center);
      min-width: 40px;
      background: var(--white);
      position: relative;

      @include noTouchHover(){
        > span{
          opacity: 1;
          transform: translate(0, 10%);
        }
      }

      > span{
        font: 500 12px var(--main-font);
        pointer-events: none;
        position: absolute;
        top: 120%;
        left: 0;
        transform: translate(0, 20%);
        opacity: 0;
        white-space: nowrap;
        transition: transform 350ms var(--ease-in-out-circ),
                    opacity 350ms var(--ease-in-out-circ);

        @include responsive($tabletSmall){
          @include font-vw(12);
        }

        @include responsive($deskXL){
          font-size: 12px;
        }
      }

      > .Icon{
        pointer-events: none;
      }
    }
  }
}