.ArchivePrestationParentPresentation{
  .wrapper{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    @include responsive($tabletSmall){
      padding: 0 space(1);
    }

    > *{
      width: 100%;
    }
  }

  h2{
    @include responsive($tabletSmall){
      max-width: space(9);
    }
  }

  .wswyg--content{
    @include responsive($tabletSmall){
      max-width: get-vw(500);
      margin-left: auto;
    }
  }
}