.AboutDelefortrie{
  padding-top: 0;

  > .visual__container{
    @include imgRatio(1440, 700);
  }

  > .container{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
      padding-top: 5em;
    }

    @include responsive($tabletSmall){
      align-items: center;
      padding-top: get-vw(120);
    }

    .wrapper,
    .visual__container{
      @include responsive($tabletSmall){
        max-width: space(9);
      }
    }

    .wrapper{
      @include flex(flex-start);
      flex-direction: column;
    }

    .visual__container{
      @include imgRatio(540, 570);

      @include responsive($tabletSmall, max){
        margin-top: 2em;
      }
    
      @include responsive($tabletSmall){
        margin-left: auto;
      }
    }
  }
}