.HomePrestations{
  h2{
    @include responsive($tabletSmall){
      margin-bottom: get-vw(50);
    }
  }

  .container > .wrapper{
    display: grid;
    
    @include responsive($tabletSmall, max){
      grid-template-columns: 1fr;
      grid-gap: 40px;
    }
    
    @include responsive($tabletSmall){
      grid-template-columns: repeat(2, 1fr);
      grid-gap: get-vw(40);
    }
  }
}
