.PrestationSubcategoryCard{
  padding: 100px 70px;
  min-height: 500px;

  @include noTouchHover() {
    .wrapper{
      transform: scale(0.95);
    }

    .visual__container{
      transform: scale(1.05);
    }
  }

  &, .wrapper{
    position: relative;
    @include flex(flex-start);
    flex-direction: column;
  }

  .wrapper{
    margin-top: auto;
    z-index: 1;
    transition: transform 800ms var(--ease-out-quart);

    > span{
      font: 400 30px var(--title-font);

       @include responsive($tabletSmall) {
          @include font-vw(40);
       }

       @include responsive($deskXL){
        font-size: 40px;
      }
    }
  }
}
