.Icon {
  position: relative;
  @include imgRatio(1, 1);
  // min-width: 24px;

  svg {
    @include cover();
    // fill: var(--base);
  }

  &[data-name="play"] {
    @include imgRatio(17, 20);
    min-width: 17px;
  }

  &[data-name="tick"] {
    width: get-vw(24);

    @include responsive($tabletSmall, max){
      min-width: 24px;
    }
  }

  &[data-name="clipboard"]{
    @include imgRatio(16, 16);
    width: get-vw(16);

    @include responsive($tabletSmall, max){
      min-width: 16px;
    }

    svg > *{
      fill: none;
    }
  }

  &[data-name="whatsapp"]{
    @include imgRatio(20, 20);
    width: get-vw(20);

    @include responsive($tabletSmall, max){
      min-width: 20px;
    }
  }

  &[data-name="email"]{
    @include imgRatio(17, 14);
    width: get-vw(17);

    @include responsive($tabletSmall, max){
      min-width: 17px;
    }
  }

  &[data-name="linkedin"]{
    @include imgRatio(15, 14);
    width: get-vw(15);

    @include responsive($tabletSmall, max){
      min-width: 15px;
    }
  }

  &[data-name="facebook"]{
    @include imgRatio(9, 16);
    width: get-vw(9);

    @include responsive($tabletSmall, max){
      min-width: 9px;
    }

    // .Footer &{
    //   width: get-vw(11);

    //   @include responsive($tabletSmall, max){
    //     min-width: 11px;
    //   }
    // }
  }

  &[data-name="twitter"]{
    @include imgRatio(18, 14);
    width: get-vw(18);

    @include responsive($tabletSmall, max){
      min-width: 18px;
    }
  }

  &[data-name="google"]{
    @include imgRatio(32, 32);
    width: get-vw(32);

    @include responsive($tabletSmall, max){
      min-width: 30px;
    }
  }

  &[data-name="arrow"]{
    @include imgRatio(21, 18);
    width: get-vw(21);

    @include responsive($tabletSmall, max){
      min-width: 21px;
    }
  }

  &[data-name="arrow-small"]{
    @include imgRatio(12, 10);
    width: get-vw(12);

    @include responsive($tabletSmall, max){
      min-width: 12px;
    }

    svg > *{ stroke: var(--white) }
  }

  &[data-name="step"]{
    @include imgRatio(64, 64);
    width: get-vw(64);

    @include responsive($tabletSmall, max){
      min-width: 50px;
    }
  }

  &[data-name="chevron"]{
    @include imgRatio(24, 24);
    width: get-vw(24);

    @include responsive($tabletSmall, max){
      min-width: 24px;
    }
  }

  &[data-name="instagram"]{
    @include imgRatio(24, 24);
    width: get-vw(18);

    @include responsive($tabletSmall, max){
      min-width: 18px;
    }
  }
  
  &[data-name="check"],
  &[data-name="non_check"]{
    @include imgRatio(20, 20);
    width: get-vw(20);

    @include responsive($tabletSmall, max){
      min-width: 20px;
    }
  }

  &[data-name="bubble"]{
    @include imgRatio(18, 18);
    width: get-vw(18);

    @include responsive($tabletSmall, max){
      min-width: 18px;
    }

    svg > *{ stroke: var(--white) }
  }
}
