.Footer {
   background: var(--base);
   color: var(--white);
   position: relative;
   z-index: 10;

   @include responsive($tabletSmall, max) {
      padding: 10em 0 5em;
   }

   @include responsive($tabletSmall) {
      padding: get-vw(150) 0 get-vw(50);
   }

   .main {
      @include flex(flex-start);

      @include responsive($tabletSmall, max) {
         flex-direction: column;
      }

      > * {
         width: 100%;
      }

      .ContactInfos {
         @include responsive($tabletSmall, max) {
            margin-bottom: 3em;
         }

         @include responsive($tabletSmall) {
            //   max-width: space(5);
            max-width: get-vw(250);
            margin-right: space(1);
         }
      }

      .blocks {
         @include responsive($tabletSmall, max) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 2em 1em;
         }

         @include responsive($tabletSmall) {
            @include flex(flex-start, space-between);
         }
      }
   }

   .sub {
      &,
      > ul {
         @include flex(flex-start);

         @include responsive($tabletSmall, max) {
            flex-direction: column;
         }
      }

      @include responsive($tabletSmall, max) {
         flex-direction: column;
         margin-top: 3em;
         gap: 2em;
      }

      @include responsive($tabletSmall) {
         margin-top: get-vw(100);
         align-items: center;
         justify-content: space-between;
      }

      > ul,
      .Copyright {
         filter: opacity(0.5);

         * {
            font: 500 12px var(--main-font);

            @include responsive($tabletSmall) {
               @include font-vw(12);
            }

            @include responsive($deskXL){
              font-size: 12px;
            }
         }
      }

      > ul {
         gap: 1em;
      }
   }
}
