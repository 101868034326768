@import url("../../node_modules/swiper/swiper-bundle.min.css");

.swiper {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;

  &-wrapper {
    order: 2;
    cursor: grab;

    .swiper-container:not(.swiper-container-free-mode) &{
      transition: transform 600ms $ease!important;
    }

    .swiper-container.--dragging & {
      transition: none!important;
    }
  }

  &-slide {
    height: auto;
    transition: transform 600ms $ease,
                opacity 300ms $ease!important;
  }

  &-navigation {
    // margin-bottom: 20px;
    @include flex(center, flex-end);
    order: 2;

    @include responsive($tabletSmall, max){
      margin-top: 2em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(40);
    }

    > * {
      @include flex(center, center);
      cursor: pointer;
      height: get-vw(60);
      width: get-vw(60);
      min-height: 45px;
      min-width: 45px;
      transition: background 250ms var(--ease-in-out-circ);
      border-radius: 50%;
      background: var(--black);

      @include responsive($tabletSmall, max){
        min-height: 60px;
        min-width: 60px;
      }

      &.swiper-button-disabled {
        background: none;
        pointer-events: none;
        border: 1px solid #C6C6C6;

        .Icon[data-name="arrow"] svg > *{
          stroke: #C6C6C6;
        }
      }

      &+* { margin-left: 0.8em; }

      .Icon[data-name="arrow"]{
        svg > *{ 
          stroke: var(--white);
          transition: stroke 250ms var(--ease-in-out-circ);
        }
      }

      &.prev{
        .Icon[data-name="arrow"]{
          transform: scaleX(-1);
        }
      }
    }
  }

  &-pagination {
    order: 3;
    position: relative!important;
    margin-top: 20px!important;
    bottom: 0px!important;
    @include flex(center, flex-end);

    > * {
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background: var(--base);
      cursor: pointer;
      transition: all 240ms $easeOutQuad;

      .--dark & {
        background: var(--grey-l);
      }

      @include noTouchHover() {
        transform: scale(1.1);
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      &.--active {
        background: var(--links-color);
      }
    }
  }

  &-scrollbar {
    order: 4;
    position: relative!important;
    left: 0%!important;
    width: 100%!important;
    margin-top: 20px;
    height: 2px!important;
    background: var(--grey-l);

    .--dark & {
      background: var(--black);
    }

    &-drag {
      position: absolute;
      top: 0px;
      background: var(--base);
      border-radius: 4px;
      cursor: pointer;

      .--dark & {
        background: var(--grey-l);
      }
    }
  }
}
