.Values{
  .head{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    @include responsive($tabletSmall){
      padding: 0 space(1);
    }

    h2{
      color: var(--white);
      
      @include responsive($tabletSmall){
        max-width: space(8);
      }
    }
    
    .wswyg--content{
      color: var(--grey);

      @include responsive($tabletSmall){
        max-width: space(9);
        margin-left: auto;
      }
    }
  }

  .cards{
    @include flex();

    @include responsive($tabletSmall, max){
      margin-top: 2em;
      flex-direction: column;
      gap: 1em;
    }

    @include responsive($tabletSmall){
      margin-top: get-vw(70)
    }
  }
}
