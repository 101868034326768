h1,
h2,
h3,
h4,
h5,
h6 {
   font-weight: 500;
   color: var(--black);
}

h1 {
   font: 500 35px var(--title-font);
   line-height: 1.2;

   @include responsive($tabletSmall) {
      @include font-vw(50);
   }

   @include responsive($deskXL){
     font-size: 50px;
   }
}

h2 {
   font: 400 30px var(--title-font);
   margin-bottom: 1em;

   @include responsive($tabletSmall) {
      @include font-vw(40);
   }

   @include responsive($deskXL){
     font-size: 40;
   }
}

h3 {
   font: 400 24px var(--main-font);
   margin-bottom: 1em;

   @include responsive($tabletSmall) {
      @include font-vw(24);
   }

   @include responsive($deskXL){
     font-size: 24px;
   }
}

h4 {
   font: 400 22px var(--main-font);
   margin-bottom: 1em;

   @include responsive($tabletSmall) {
      @include font-vw(22);
   }

   @include responsive($deskXL){
     font-size: 22px;
   }
}

h5 {
   font: 400 20px var(--main-font);
   margin-bottom: 1em;

   @include responsive($tabletSmall) {
      @include font-vw(20);
   }

   @include responsive($deskXL){
     font-size: 20px;
   }
}

h6 {
   font: 400 18px var(--main-font);
   margin-bottom: 1em;

   @include responsive($tabletSmall) {
      @include font-vw(18);
   }

   @include responsive($deskXL){
     font-size: 18px;
   }
}

strong {
   font-weight: 500;
}

p,
ul,
ol,
li {
   @include responsive($tabletSmall, max) {
      font-size: 16px;
   }

   @include responsive($tabletSmall) {
      @include font-vw(16);
   }

   @include responsive($deskXL){
     font-size: 16px;
   }

   &.no__results {
      text-align: center;
      font-size: 3.2rem;
      font-weight: bold;
      opacity: 0.2;
      margin-top: 30px;
   }
}

.mid-title{
   font: 500 22px var(--title-font);

   @include responsive($tabletSmall){
     @include font-vw(22);
   }

   @include responsive($deskXL){
     font-size: 22px;
   }
}

.highlight {
   position: relative;
   z-index: 1;

   &.--show-highlight{
      strong:before{
         // transform: scaleX(1.1);
      }

      &.--neg{
         strong:before {
            transform: scale(1, 1) rotate(-1deg);
         }
      }

      &.--pos{
         strong:before{
            transform: scale(1, 1) rotate(1deg);
         }
      }
   }

   &.--neg {
      strong:before {
         transform: scale(0, 1);
      }
   }

   &.--pos {
      strong:before {
         transform: scale(0, 1);
      }
   }

   &.--blue-l {
      strong:before {
         background: var(--blue-l1);
      }
   }

   &.--blue-d {
      strong:before {
         background: var(--blue-d1);
      }
   }

   &.--blue {
      strong:before {
         background: var(--blue);
      }
   }

   &.--green-d {
      strong:before {
         background: var(--green-d1);
      }
   }

   &.--green {
      strong:before {
         background: var(--green);
      }
   }

   strong {
      position: relative;
      display: inline-block;

      &:before {
         @include content();
         position: absolute;
         top: 0; left: -0.15em;
         height: 100%; width: 105%;
         pointer-events: none;
         z-index: -1;
         transform-origin: 0% 0%;
         transition: transform 550ms var(--ease-in-out-quart);
      }
   }
}

.tag {
   font: 600 12px var(--title-font);
   position: relative;
   z-index: 1;
   text-transform: uppercase;
   line-height: 2;

   @include responsive($tabletSmall) {
      @include font-vw(12);
   }

   @include responsive($deskXL){
     font-size: 12px;
   }

   &:before {
      @include content();
      @include cover();
      pointer-events: none;
      z-index: -1;
      transform: scaleX(1.1);
   }

   &.--neg {
      &:before {
         transform: scale(1.05) rotate(-1deg);
      }
   }

   &.--pos {
      &:before {
         transform: scale(1.05) rotate(1deg);
      }
   }

   &.--blue-l {
      &:before {
         background: var(--blue-l1);
      }
   }

   &.--blue-d {
      &:before {
         background: var(--blue-d1);
      }
   }

   &.--blue {
      &:before {
         background: var(--blue);
      }
   }

   &.--green-d {
      &:before {
         background: var(--green-d1);
      }
   }

   &.--green {
      &:before {
         background: var(--green);
      }
   }
}

.wswyg--content {
   &:not(.--center) {
      text-align: left;
   }

   * {
      color: inherit;
   }

   > * + * {
      margin-top: 1em;
   }

   h2 {
      font: 500 22px var(--title-font);
      color: var(--black);

      @include responsive($tabletSmall) {
         @include font-vw(22);
      }

      @include responsive($deskXL){
        font-size: 22px;
      }
   }

   ul,
   ol {
      padding-left: 0.8em;

      > li + li {
         margin-top: 0.8em;
      }

      code {
         margin-top: 1em;
         margin-bottom: 1em;
      }
   }

   em{
      font-style: italic;
   }

   strong{
      font-weight: 700;
   }

   ul {
      list-style: disc;
   }

   ol {
      list-style-type: decimal;
   }

   figure {
      margin: 40px auto;
      overflow: hidden;

      &:last-child {
         margin-bottom: 0px;
      }

      img {
         display: block;
      }
   }

   blockquote {
      padding-left: 1em;
      border-left: 2px solid var(--grey);

      p {
         font-weight: 500;
      }
   }

   a {
      color: var(--links-color);
      font-weight: bold;
      text-decoration: underline;
   }

   img {
      width: 100%;

      @include responsive($tabletSmall, max) {
         margin: 3em 0;
      }

      @include responsive($tabletSmall) {
         margin: get-vw(50) 0;
      }
   }
}
