.Form {
  &.QuotationForm,
  &.PatronageForm{
    width: 100%;

    @include responsive($tabletSmall, max){
      margin-top: 4em;
      padding: 2em;
    }

    @include responsive($tabletSmall){
      max-width: space(11);
      margin-left: auto;
      padding: get-vw(60);
    }
  }

  span.--error {
    font-size: 10px;
    color: var(--error-color);
    position: absolute;
    top: 70%;
    left: 2em;
  }

  .row {
    @include flex(flex-start, flex-start);

    @include responsive($tabletSmall) {
      margin-right: -30px;
    }

    > * {
      width: 100%;
      margin-bottom: 30px;

      @include responsive($tabletSmall) {
        max-width: calc(50% - 30px);
        margin-right: 30px;
      }

      &.--full {
        @include responsive($tabletSmall) {
          max-width: calc(100% - 30px);
          min-width: calc(100% - 30px);
          margin-right: 30px;
        }
      }
    }
  }

  label:not(.Checkbox),
  select{
    sup{
      font: 500 1em var(--main-font);
      color: var(--green);
    }
  }

  &__group {
    @include flex();
    flex-direction: column;
    position: relative;
    width: 100%;

    & + .Form__group{
      @include responsive($tabletSmall, max){
        margin-top: 1em;
      }

      @include responsive($tabletSmall){
        margin-top: get-vw(10);
      }
    }

    &:not(.--select){
      label{
        position: absolute;
        left: 1.2em;
        transform-origin: 0% 50%;
        transition: all 400ms $easeOutExpo;
        pointer-events: none;
        color: rgba(56, 56, 55, 0.7);
      }
    }

    &:not(.--textarea):not(.--select) {
      label {
        top: 50%; 
        transform: translate(0, -50%) scale(1);
      }

      &.--focused,
      &.--filled {
        label {
          transform: translate(0, -140%) scale(0.8);
        }
      }
    }

    &.--textarea{
      label{  
        top: 1.2em;
        transform: translate(0, 30%) scale(1);
      }

      &.--focused,
      &.--filled{
        label{
          transform: translate(0, -50%) scale(0.8);
        }
      }
    }
  }

  &__control {
    all: unset;
    padding: 1.2em;
    min-height: 42px;
    border: 1px solid var(--grey);
    color: var(--black);
    // width: 100%;

    .--error & {
      border-color: var(--error-color);
    }

    &::placeholder {
      opacity: 0;
    }
  }

  .select-container{
    @include flex();
    flex-wrap: wrap;
    position: relative;

    &.--error{
      .fake-label{
        border-color: var(--error-color);
      }
    }

    &.--has-value{
      .fake-label{
        display: none;
      }
    }

    &, select{
      width: 100%;
    }
    
    .fake-label{
      @include flex(center, flex-start);
      position: absolute;
      height: 100%; width: 100%;
      top: 0; left: 0;
      background: var(--white);
      pointer-events: none;
      border: 1px solid var(--grey);
      padding: 1.2em;

      

      sup{
        color: var(--green);
      }
    }

    select.Form__control {
      line-height: 42px;
      padding-right: 3em;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .Icon[data-name="chevron"]{
      position: absolute;
      top: 50%; right: 1.2em;
      transform: translate(0, -50%);
      pointer-events: none;
    }
  }

  textarea.Form__control {
    min-height: 200px;
    max-height: 200px;
    padding-top: 2em;
  }

  &__footer{
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
      margin-top: 2em;  
    }

    @include responsive($tabletSmall){
      align-items: center;
      margin-top: get-vw(40);
    }

    p{
      font: 500 12px var(--main-font);

      @include responsive($tabletSmall){
        @include font-vw(12);
        max-width: get-vw(310);
      }

      @include responsive($deskXL){
        font-size: 12px;
      }
    }

    .Form__actions{
      @include responsive($tabletSmall, max){
        margin-top: 1.5em;
      }

      @include responsive($tabletSmall){
        margin-left: auto;
      }
    }
  }

  &__actions {
    .Btn {
      margin-top: 0;
    }
  }

  &__output {
    color: var(--valid-color);

    p:not(:empty) {
      margin-top: 2em;
    }
  }
}
