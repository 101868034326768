.ValuesCard{
  padding: 3em;

  @include responsive($tabletSmall){
    max-with: space(12);
  }

  &:not(:nth-child(2)){
    background: var(--white);
  }

  &:nth-child(2){
    background: var(--green-l2);
  }

  .inner{
    @include flex(flex-start);
    flex-direction: column;
    height: 100%;
  }

  // Title
  .inner > span{
    font: 500 40px var(--title-font);
    color: var(--black);
    line-height: 1;
    padding-bottom: 1.5em;

    @include responsive($tabletSmall){
      @include font-vw(80);
    }

    @include responsive($deskXL){
      font-size: 80px;
    }
  }

  .inner > .wswyg--content{
    margin-top: auto;
  }
}