/* -----
Misc
----- */

.--hidden {
  display: none !important;
}

.--centered {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.--left {
  margin-right: auto;
  text-align: left;
}

.--right{
  margin-left: auto;
  text-align: right;
}

.--sr-only {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    left: -9999px;
    top: -9999px;
}

.--no-br br {
  content: '';
  width: 9px;
  height: 18px;
  &:before {
    content: ' '
  }
}

.--mobile-swiper{
  @include responsive($tabletSmall){
    .swiper-wrapper{
      transform: none !important;
      cursor: initial !important;
    }

    .swiper-slide{
      margin: 0 !important;
    }
  }
}

.--no-sb{
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
  }
}

.--oh{
  overflow: hidden;
}

.--pr{
  position: relative;
}

/* -----
Lazyload
----- */

html:not(.no-js) .lazyload,
html:not(.no-js) .lazyloading {
  opacity: 0;
}

html.no-js img.sr-only {
  display:block;
  top:0; left:0;
  width:100%;
  height:100%;
  clip:inherit;
  object-fit:cover;
}

.lazyloaded {
  position: relative;
  opacity: 1;
  transition: opacity 400ms linear 400ms;
}

/* -----
Prllx
----- */

*[data-prllxfrom] {
  will-change:transform;

  &[data-mobile-fix] {
    @include responsive($tabletSmall, max) {
      transform: none!important;
    }
  }
}

/* -----
Backgrounds
----- */

.--bg-black-l{
  background: var(--black);
}

.--bg-black{
  background: var(--black-d1);
}

.--bg-white{
  background: var(--white);
}

.--bg-grey{
  background: var(--grey);
}

.--bg-green{
  background: var(--green);
}

.--bg-blue-d{
  background: var(--blue-d1);
}

.--bg-blue{
  background: var(--blue);
}

.--bg-green-l2{
  background: var(--green-l2);
}

/* -----
Colors
----- */

.--c-white{
  color: var(--white);
}

.--c-black{
  color: var(--black);
}

.--c-grey-d{
  color: var(--grey-d1);
}

.--c-text{
  color: var(--text);
}

/* -----
Alignment
----- */

.--center{
  text-align: center;
}

.--uppercase{
  text-transform: uppercase;
}
