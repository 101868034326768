.Sharing{
  > ul{
    @include flex();
    gap: 0.5em;

    a, button{
      @include imgRatio(40, 40);
      @include flex(center, center);
      min-width: 40px;
      background: var(--white);
      position: relative;

      @include noTouchHover(){
        > span{
          opacity: 1;
          transform: translate(0, 10%);
        }
      }

      > span{
        font: 500 12px var(--main-font);
        color: var(--text);
        pointer-events: none;
        position: absolute;
        top: 120%;
        left: 0;
        transform: translate(0, 20%);
        opacity: 0;
        white-space: nowrap;
        transition: transform 350ms var(--ease-in-out-circ),
                    opacity 350ms var(--ease-in-out-circ);

        @include responsive($tabletSmall){
          @include font-vw(12);
        }

        @include responsive($deskXL){
          font-size: 12px;
        }
      }

      > .Icon{
        pointer-events: none;
      }
    }

    button{
      > .Icon{
        transition: transform 550ms var(--ease-in-out-circ),
                    opacity 550ms var(--ease-in-out-circ);
      }

      .Icon[data-name="tick"]{
        opacity: 0; 
        transform: translate(-50%, -50%) scale(0.6);
        position: absolute;
        top: 50%; left: 50%;
      }

      .pop{
        > span{
          transition: opacity 550ms var(--ease-in-out-circ);

          &:nth-child(2){
            position: absolute;
            top: 0; left: 0;
            white-space: nowrap;
            opacity: 0;
          }
        }
      }

      &.--copied{
        .Icon[data-name="clipboard"]{
          opacity: 0; 
          transform: scale(0.6);
        }

        .Icon[data-name="tick"]{
          opacity: 1; 
          transform: translate(-50%, -50%) scale(1);
        }

        .pop{
          > span{
            &:first-child{
              opacity: 0;
            }

            &:nth-child(2){
              opacity: 1;
            }
          }
        }
      }
    }

    .only-mobile{
      @include responsive($tabletSmall){
        display: none;
      }
    }
  }
}