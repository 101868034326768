@font-face {
  font-family: general-sans;
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url(../fonts/GeneralSans-Medium.woff2) format("woff2"), url(../fonts/GeneralSans-Medium.woff) format("woff");
}

@font-face {
  font-family: inter;
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url(../fonts/Inter-Medium.woff2) format("woff2"), url(../fonts/Inter-Medium.woff) format("woff");
}