.App {
  width: 100%;
  min-height: 100vh;
  background: #F3F3F3;
  position: relative;
  z-index: 10;

  html:not(.no-js) body.--loading & {
    pointer-events: none;
  }

  html.--dark & {
    background: var(--base);
    color: var(--white);
  }
}

.container {
  width: 100%;
  padding: 0 4vw;
  position: relative;

  @include responsive($tabletSmall) {
    padding: 0 calc(100vw / 24);
  }

  &.--col-2{
    @include responsive($tabletSmall){
      padding: 0 calc(100vw / 12);
    }
  }
}

section {
  padding: 5em 0;

  @include responsive($tabletSmall) {
    padding: get-vw(100) 0;
  }
}

// Pages with patterns as backgrounds
// Args: Page title | slug for the img path
$pages: 'Home' 'home',
        'About' 'about',
        'JoinUs' 'joinus',
        'Accompaniment' 'accompaniment',
        'QuotationRequest' 'quotationrequest',
        'Patronage' 'patronage',
        'Realisation' 'realisation',
        'Realisations' 'realisations',
        'ArchivePrestationParent' 'prestation-category',
        'ArchivePrestationChild' 'prestation-subcategory',
        'Prestation' 'prestation';

@each $page, $slug in $pages {
  .#{$page}{
    background: url('../imgs/backgrounds/bg-#{$slug}.svg') top center no-repeat;
  }
}
